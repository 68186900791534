// This file is named themes_app because the webpack build outputs an html
// include that does not take into account folder paths. If something else was
// named app.js elsewhere, it would conflict with this.
import '../../../libs/iframeResizer/iframeResizer.contentWindow';
import { App } from '@uberflip/themes-app';

const init = (config) => {
  const app = new App(config);
  app.init();
};

export { init };
